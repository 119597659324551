<template>
  <section>
    <nav-menu></nav-menu>
    <div class="page-content-lightestblue-main">
      <div class="page-content-lightestblue-main__container">
        <div class="page-content-lightestblue-main__container-colLeft">
          <app-image :alt="bargeld-fail"
            :src="
              require('../assets/img/PB_Slider_Image_Fail_920x520_M.png')
            "
          ></app-image>
        </div>
        <div class="page-content-lightestblue-main__container-colRight px-8">
          <h1>{{ t("LBL_BARGELD_FAIL_TITLE") }}</h1>
          <div class="py-3">
            <p v-html="t('LBL_BARGELD_FAIL_TEXT')"></p>
<!--            <router-link to="/">{{-->
<!--              t("LBL_BARGELD_SUCCESS_BUTTON")-->
<!--            }}</router-link>-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NavMenu from "@/components/Menu/NavMenu";
import AppImage from "@/components/Image/Image";
import { useI18n } from "vue-i18n";

export default {
  name: "BargeldFail",
  components: {
    NavMenu,
    AppImage,
  },
  data() {
    return {
      bargeld: {
        points: "",
        euro: "",
      },
      showContinueButton: false,
      showIframe: false,
    };
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
</script>
<style lang="postcss" scoped>
.page-content-lightestblue-main {
  background-color: #e5ecf6;
  &__container {
    &-colRight {
      @apply col-span-1;
    }
    @media (min-width: 992px) {
      @apply grid-cols-2;
    }
  }
}

.page-content-white {
  @apply items-start;
  &__container {
    @apply grid grid-cols-2 gap-4 grid-flow-row-dense items-start text-left py-4;
    &-colLeft {
      @apply col-span-1;
      @media (max-width: 600px) {
        @apply col-span-1;
      }
    }
    &-colRight {
      @apply col-span-1;
    }
    @media (min-width: 992px) {
      @apply grid-cols-2;
    }
    @media (max-width: 800px) {
      @apply grid-cols-1;
    }
  }
  .content-items {
    @apply flex flex-col justify-center items-center text-dark px-4 text-center;
    & > div {
      img {
        width: auto;
        margin-bottom: 20px;
      }
    }
    &--form {
      &-blue-head {
        @apply flex justify-between items-center text-white px-2 py-2 gap-2 bg-lightblue font-bold;
        p {
          @apply mb-0;
          font-family: PAYBACKLight, "Open Sans", Arial, "Helvetica Neue",
            Helvetica, sans-serif;
          font-size: 18px;
          span {
            white-space: nowrap;
          }
          @media screen and (max-width: 430px) {
            font-size: 16px;
          }
        }
      }
      &-container {
        @apply flex justify-between items-center;
      }
      input {
        @apply w-full bg-white border border-lightblue p-2;
        &:read-only {
          color: #939393;
          background: #f2f6fb;
          cursor: not-allowed;
        }
      }
      &-form > div {
        @apply flex flex-col items-start justify-between py-2 w-full my-2;
        label {
          @apply my-2 text-blue;
        }
      }
      &-iframe {
        &-buttons {
          @apply flex-row justify-between w-full;
          button.blue {
            width: auto;
            max-width: 45%;
          }
        }
      }
    }
  }
}

.page-content-blue {
  &__container {
    @apply grid grid-cols-1 gap-2 grid-flow-row-dense  text-center py-8;
    .btn-weiter {
      width: 20%;
    }
  }
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #a5a5a6;
}
</style>
