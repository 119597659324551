<template>
      <section>
            <nav-menu></nav-menu>
            <div class="page-content-lightestblue-main">
                  <div class="page-content-lightestblue-main__container">
                        <div class="page-content-lightestblue-main__container-colLeft">
                              <app-image :alt="bargeld-form" :src="require('../assets/img/PB_Slider_Image_Einloesen_920x700_L.png')"></app-image>
                        </div>
                        <div class="page-content-lightestblue-main__container-colRight px-8">
                              <h1>{{ t("LBL_BARGELD_REDEEM_TITLE") }}</h1>
                              <div class="py-3">
                                    {{ t("LBL_BARGELD_REDEEM_TEXT") }}
                              </div>
                        </div>
                  </div>
            </div>

            <div class="page-content-white">
                  <div class="page-content-white__container">
                        <div class="page-content-white__container-colLeft">
                              <div class="content-items">
                                    <h3>{{ t("LBL_BARGELD_HOW_TO_TITLE") }}</h3>
                                    <div>
                                          <img src="../assets/img/PB_HalfStrap_Image_Einloesen_720x280_S.png" alt="Im Prämienshop" />
                                    </div>
                                    <div>{{ t("LBL_BARGELD_HOW_TO_TEXT") }}.</div>
                              </div>
                        </div>
                        <div class="page-content-white__container-colRight">
                              <div class="content-items">
                                    <div class="content-items--form">
                                          <div class="content-items--form-blue-head">
                                                <p>
                                                      <span>{{ t("LBL_USER_POINTS") }}:</span>&nbsp;{{ customerPoints }}&nbsp;°P
                                                </p>
                                                <p>
                                                      <span>{{ t("LBL_POINTS_TO_REDEEM") }}:</span>&nbsp;{{ redeemablePoints }}&nbsp;°P
                                                </p>
                                          </div>
                                          <div class="content-items--form-container">
                                                <div class="content-items--form-form content-items--form-points pr-1">
                                                      <div>
                                                            <label for="points">{{ t("LBL_VALUE_POINTS") }}* </label>
                                                            <input placeholder="0" v-model="bargeld.points" @input="calculateEuros()" name="points" class="input" />
                                                      </div>
                                                </div>
                                                <div class="content-items--form-form content-items--form-euro pr-1">
                                                      <div>
                                                            <label for="euros">{{ t("LBL_VALUE_EUROS") }} </label>
                                                            <input placeholder="0,00" v-model="bargeld.euro" name="euros" class="input" @input="calculatePoints()" />
                                                      </div>
                                                </div>
                                          </div>
                                          <div class="alert" v-html="alertText"></div>
                                          <hr />

                                          <div class="py-3" v-if="showContinueButton && !showIframe">
                                                <Button v-if="alertText === ''" :css-class="'blue'" :text="t('LBL_CONTINUE')" class="btn-weiter" @click="showPaymentIframe()"></Button>
                                          </div>
                                          <div class="content-items--form-iframe" v-if="showIframe">
                                                <SecurePay :mode="'cashdiscount'" :fixedHeight="'14rem'" :requestType="'init'" :paymentValue="bargeld.points" style="width: 100%; margin: 0 auto; max-height: 100%; min-height: 14rem"></SecurePay>
                                                <div class="content-items--form-iframe-buttons sm:w-auto">
                                                      <Button :css-class="'blue'" :text="t('LBL_CONTINUE')" class="btn-weiter" @click="goToSuccess()"></Button>
                                                      <Button :css-class="'blue'" :text="t('LBL_EDIT_POINTS')" class="btn-weiter" @click="changePoints()"></Button>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </section>
</template>

<script>
      import Button from "@/components/Button/Button";
      import NavMenu from "@/components/Menu/NavMenu";
      import AppImage from "@/components/Image/Image";
      import { useI18n } from "vue-i18n";
      import SecurePay from "@/components/ShoppingCart/SecurePay";
      import { computed, onMounted } from "vue";
      import { useStore } from "vuex";
      import securePay from "@/composables/securePay";
      import EventBus from "@/helpers/event-bus";

      export default {
            name: "BargeldForm",
            components: {
                  NavMenu,
                  AppImage,
                  Button,
                  SecurePay
            },
            data() {
                  const store = useStore();

                  const customerPoints = computed(() => {
                        return store.getters["auth/customerPoints"];
                  });
                  const goToSuccess = async () => {
                        EventBus.emit("savePointsToRedeem", this.bargeld.points);
                        securePay.sendPostMessage("check-validity");
                  };
                  return {
                        bargeld: {
                              points: "",
                              euro: ""
                        },
                        showContinueButton: false,
                        showIframe: false,
                        alertText: "",
                        minPoints: 200,
                        redeemablePoints: customerPoints.value < 200 ? 0 : customerPoints.value <= 3e4 ? customerPoints.value : 3e4,
                        customerPoints,
                        goToSuccess
                  };
            },
            methods: {
                  calculateEuros() {
                        this.bargeld.points = this.bargeld.points.replace(/[^0-9]/g, "");
                        this.bargeld.euro = (Math.round(this.bargeld.points * 100) / 10000).toFixed(2).replace(".", ",");
                        this.validateForm();
                        setTimeout(() => (this.showContinueButton = true), 1000);
                  },
                  calculatePoints() {
                        this.bargeld.euro = this.bargeld.euro.replace(/[^0-9]/g, "");
                        this.bargeld.points = this.bargeld?.euro * 1000;
                        this.validateForm();
                        setTimeout(() => (this.showContinueButton = true), 1000);
                  },
                  showPaymentIframe() {
                        this.showIframe = !this.showIframe;
                        var list, i;
                        list = document.getElementsByClassName("input");
                        for (i = 0; i < list.length; ++i) {
                              list[i].setAttribute("readonly", "readonly");
                        }
                  },
                  changePoints() {
                        this.showIframe = !this.showIframe;

                        var list, i;
                        list = document.getElementsByClassName("input");
                        for (i = 0; i < list.length; ++i) {
                              list[i].removeAttribute("readonly", "readonly");
                        }
                  },
                  validateForm() {
                        const enteredBargeldPoints = Number(this.bargeld.points).toFixed(2);
                        switch (true) {
                              case enteredBargeldPoints < this.minPoints:
                                    this.alertText = "Die von Ihnen angegebenen <strong>" + this.bargeld.points + "°P</strong> sind leider nicht ausreichend. Sie müssen mindestens <strong>200 °P</strong> einlösen.";
                                    break;
                              case enteredBargeldPoints > this.redeemablePoints:
                                    this.alertText = "Bitte geben Sie einen niedrigeren Punktebetrag ein";
                                    break;
                              default:
                                    this.alertText = "";
                        }
                  }
            },
            setup() {
                  const { t } = useI18n();
                  onMounted(async () => {});
                  return {
                        t
                  };
            }
      };
</script>
<style lang="postcss" scoped>
      .page-content-lightestblue-main {
            background-color: #e5ecf6;
            &__container {
                  &-colRight {
                        @apply col-span-1;
                        height: 100%;
                  }
                  @media (min-width: 992px) {
                        @apply grid-cols-2;
                  }
            }
      }

      .page-content-white {
            @apply items-start;
            &__container {
                  @apply grid grid-cols-2 gap-4 grid-flow-row-dense items-start text-left py-4;
                  @media (max-width: 800px) {
                        width: 100%;
                  }
                  &-colLeft {
                        @apply col-span-1;
                        @media (min-width: 992px) {
                              @apply grid-cols-2;
                              @apply col-span-2;
                        }
                        @media (max-width: 800px) {
                              @apply col-span-2;
                        }
                        @media (max-width: 600px) {
                              @apply col-span-1;
                        }
                  }
                  &-colRight {
                        @apply col-span-1;
                        height: 100%;
                        @media (min-width: 992px) {
                              @apply grid-cols-4 col-span-4;
                        }
                        @media (max-width: 800px) {
                              @apply col-span-2;
                        }
                        @media (max-width: 600px) {
                              @apply col-span-1;
                        }
                  }
                  @media (min-width: 992px) {
                        @apply grid-cols-6;
                  }
                  @media (max-width: 800px) {
                        @apply grid-cols-1;
                  }
            }
            .content-items {
                  @apply flex flex-col justify-center items-center text-dark px-4 text-center;
                  & > div {
                        img {
                              width: auto;
                              margin-bottom: 20px;
                        }
                  }
                  &--form {
                        @media (max-width: 800px) {
                              width: 100%;
                        }
                        &-blue-head {
                              @apply flex justify-between items-center text-white px-2 py-2 gap-2 bg-lightblue font-bold;
                              p {
                                    @apply mb-0;
                                    font-family: PAYBACKLight, "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
                                    font-size: 18px;
                                    span {
                                          white-space: nowrap;
                                    }
                                    @media screen and (max-width: 430px) {
                                          font-size: 17px;
                                    }
                              }
                              @media screen and (max-width: 430px) {
                                    @apply flex-col;
                                    width: 100%;
                              }
                        }
                        &-container {
                              @apply flex justify-between items-center;
                        }
                        input {
                              @apply w-full bg-white border border-lightblue p-2;
                              &:read-only {
                                    color: #939393;
                                    background: #f2f6fb;
                                    cursor: not-allowed;
                              }
                        }
                        &-form > div {
                              @apply flex flex-col items-start justify-between py-2 w-full my-2;
                              label {
                                    @apply my-2 text-blue;
                              }
                        }
                        &-iframe {
                              &-buttons {
                                    @apply flex flex-row justify-between w-full;
                                    button.blue {
                                          width: auto;
                                          max-width: 45%;
                                    }
                              }
                        }
                  }
            }
      }
      .alert {
            color: red;
            font-size: 15px;
            width: 24rem;
            margin: 0 auto;
      }
      .page-content-blue {
            &__container {
                  @apply grid grid-cols-1 gap-2 grid-flow-row-dense  text-center py-8;
                  .btn-weiter {
                        width: 20%;
                        @media screen and (min-width: 320px) {
                              width: auto;
                        }
                  }
            }
      }
      hr {
            margin-top: 20px;
            margin-bottom: 20px;
            border-top: 1px solid #a5a5a6;
      }
      .btn-weiter {
            width: 20%;
            @media screen and (min-width: 320px) {
                  width: auto;
            }
      }
</style>
