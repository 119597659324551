import constants from "@/helpers/constants";
import store from "@/store/store";
import EventBus from "@/helpers/event-bus";
import router from "../router/index.js";

let securePayPointsToRedeem = 0;

EventBus.on("savePointsToRedeem", async (pointsToRedeem) => {
  securePayPointsToRedeem = pointsToRedeem;
});

let securePay = {
  initFinished: false,
  targetOrigin: constants.TARGETORIGIN,
  testOrigin: (origin) => {
    let valid = false;

    for (let i = 0; i < securePay.testOrigin.length; i++) {
      if (origin.indexOf(securePay.targetOrigin[i]) == 0) {
        valid = true;
      }
    }

    return valid;
  },
  getPaymentUrl() {
    return constants.TARGETORIGIN[0];
  },
  sendPostMessage(type) {
    let iframe = securePay.getIframe();
    if (iframe != null && iframe.contentWindow != null) {
      iframe.contentWindow?.postMessage(type, securePay.getPaymentUrl());
    }
  },
  getIframe() {
    return window.document.getElementById("securepay");
  },
  initSecurePay() {
    if (securePay.initFinished) return;
    let iframe = securePay.getIframe();
    if (iframe != null && iframe.contentWindow != null) {
      securePay.sendPostMessage("init");
    }
    setTimeout(securePay.initSecurePay, 100);
  },
  handleViewportDimension() {
    let iframe = securePay.getIframe();
    if (iframe != null && iframe.contentWindow != null) {
      let mode = window.innerWidth <= 750 ? "mobile-view" : "desktop-view";
      securePay.sendPostMessage(mode);
    }
  },
  async receiveMessage(e) {
    let origin = e.origin || e.originalEvent.origin;

    // if (!securePay.testOrigin(origin)) {
    //   return;
    // }

    let response = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;

    switch (response.request) {
      case "init":
        if (response.message == "stop_timer") {
          securePay.initFinished = true;
          //securePay.$emit('init', true);
          securePay.handleViewportDimension();
        }
        break;
      case "ping":
        if (response.message.height) {
          let iframe = securePay.getIframe();
          if (iframe != null && iframe.contentWindow != null) {
            iframe.style.height = response.message.height + 10 + "px";
          }
        }
        break;
      case "payment":
        if (response.result > 0) {
          // payment request failed
          response["cardtype"] = "";
          store.dispatch("securepay/set", response);
        } else {
          let cardtype = response.message;
          if (cardtype == "kreditkarte") {
            if (response.Kartentyp == 1) cardtype = "mastercard";
            else if (response.Kartentyp == 2) cardtype = "amex";
            else if (response.Kartentyp == 3) cardtype = "visa";
          }
          response["cardtype"] = cardtype;
          store.dispatch("securepay/set", response);
          EventBus.emit("paymentSelected", cardtype);
        }
        store.dispatch("apiLoading/setLoading", false);
        break;
      case "validity-checked":
        if (response.result == 0 && securePayPointsToRedeem) {
          if (response.message === false) {
            return
          } else {
            let params = {
              amount: securePayPointsToRedeem
            };
            const response = await store.dispatch("payment/getSuccessPaymentUrl", params);
            if (response && response.redemption_number) {
              securePay.sendPostMessage("cashforpoints");
            }
          }
        }
        break;
    }
  },
};
export default securePay;
