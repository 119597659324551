<template>
      <section>
            <nav-menu></nav-menu>
            <div class="page-content-lightestblue-main">
                  <div class="page-content-lightestblue-main__container">
                        <div class="page-content-lightestblue-main__container-colLeft">
                              <app-image :alt="bargeld-info" :src="require('../assets/img/PB_Slider_Image_General_920x700_L.png')"></app-image>
                        </div>
                        <div class="page-content-lightestblue-main__container-colRight px-8">
                              <h1>{{ t("LBL_REDEEM_POINTS_TITLE") }}</h1>
                              <div class="py-3">
                                    {{ t("LBL_REDEEM_POINTS_TEXT") }}
                              </div>
                        </div>
                  </div>
            </div>

            <div class="page-content-white">
                  <div class="page-content-white__container">
                        <div class="page-content-white__container-colLeft">
                              <div class="content-items">
                                    <div>
                                          <img src="../assets/img/PB_HalfStrap_Image_Praemienshop_freigestellt_S.png" alt="Im Prämienshop" />
                                    </div>
                                    <h2>{{ t("LBL_IM_PRAEMIENSHOP_TITLE") }}</h2>
                                    <div v-html="t('LBL_IM_PRAEMIENSHOP_TEXT')"></div>
                              </div>
                        </div>
                        <div class="page-content-white__container-colRight">
                              <div class="content-items">
                                    <div>
                                          <img src="../assets/img/PB_HalfStrap_Image_MitPunktenZahlen_freigestellt_S.png" alt="An der Kassa" />
                                    </div>
                                    <h2>{{ t("LBL_KASSA_TITLE") }}</h2>
                                    <div>
                                          {{ t("LBL_KASSA_TEXT") }}
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>

            <div class="page-content-blue">
                  <div class="page-content-blue__container">
                        <div>
                              <h1>
                                    {{ t("LBL_BARGELD_INFO_TITLE") }}
                              </h1>
                              <div>
                                    {{ t("LBL_BARGELD_INFO_TEXT") }}
                              </div>

                              <div class="py-3">
                                    <Button :css-class="'blue'" :text="t('LBL_CONTINUE')" class="btn-weiter" @click="goToForm()"></Button>
                              </div>
                        </div>
                  </div>
            </div>
      </section>
</template>

<script>
      import Button from "@/components/Button/Button";
      import NavMenu from "@/components/Menu/NavMenu";
      import AppImage from "@/components/Image/Image";
      import { useI18n } from "vue-i18n";
      import { useRouter } from "vue-router";

      export default {
            name: "BargeldInfo",
            components: {
                  NavMenu,
                  AppImage,
                  Button
            },
            setup() {
                  const { t } = useI18n();
                  const router = useRouter();
                  const goToForm = async () => {
                        router.push({
                              name: "bargeld-form"
                        });
                  };
                  return {
                        goToForm,
                        t
                  };
            }
      };
</script>
<style lang="postcss" scoped>
      .page-content-lightestblue-main {
            background-color: #e5ecf6;
            &__container {
                  &-colRight {
                        @apply col-span-1;
                  }
                  @media (min-width: 992px) {
                        @apply grid-cols-2;
                  }
            }
      }

      .page-content-white {
            &__container {
                  @apply grid grid-cols-2 gap-8 grid-flow-row-dense  text-left py-4;
                  &-colLeft {
                        @apply col-span-1;
                        @media (max-width: 600px) {
                              @apply col-span-1;
                        }
                  }
                  &-colRight {
                        @apply col-span-1;
                  }
                  @media (min-width: 992px) {
                        @apply grid-cols-2;
                  }
                  @media (max-width: 800px) {
                        @apply grid-cols-1;
                  }
            }
            .content-items {
                  @apply flex flex-col justify-center items-center text-dark px-8 text-center;
                  & > div {
                        img {
                              max-height: 100px;
                              width: auto;
                              margin-bottom: 20px;
                        }
                  }
            }
      }

      .page-content-blue {
            &__container {
                  @apply grid grid-cols-1 gap-2 grid-flow-row-dense  text-center py-8;
                  .btn-weiter {
                        width: 20%;
                        @media screen and (min-width: 320px) {
                              width: auto;
                        }
                  }
            }
      }
</style>
