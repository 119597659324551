<template>
  <div v-if="paymentUrl && paymentUrl?.url != ''" :class="show ? 'bg-white p-5 mt-2' : ''">
    <div v-show="show">
      <div class="type-h3 mt-4">
        {{ t("LBL_PAYMENT_METHOD") }}
      </div>
      <iframe id="securepay" :style="{
        height: fixedHeight,
        width: '100%',
        'background-color': 'transparent',
        border: 0
      }" title="securepay" :src="paymentUrl.url"></iframe>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import securePay from "@/composables/securePay";
import { useI18n } from "vue-i18n";

export default {
  name: "SecurePay",
  props: {
    requestType: {
      type: String
    },
    show: {
      type: Boolean,
      default: true
    },
    paymentValue: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: "checkout",
      validator(value) {
        // The value must match one of these strings
        return ["checkout", "express-checkout", "cashdiscount"].includes(value);
      },
    },
    fixedHeight: {
      type: String,
      default: "100%"
    },
    action: String
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const paymentUrl = computed(() => {
      return store.getters["payment/getPaymentUrl"];
    });

    onMounted(async () => {
      let iframeUrl = "";

      switch (props.mode) {
        case "cashdiscount":
          iframeUrl = "payment/cashdiscount-url";
          break;
        case "express-checkout":
          iframeUrl = "payment/express-checkout-url";
          break;
        default:
          iframeUrl = "payment/url";
          break;
      }

      let params = {
        mode: props.mode,
        paymentValue: props.paymentValue,
        iframeUrl: iframeUrl
      };

      await store.dispatch("payment/getPaymentUrl", params);


      securePay.initFinished = false;

      let iframe = document.getElementById("securepay");

      // Initiate the communication with the frame when the iframe is loaded
      if (iframe !== null) {
        iframe.onload = function () {
          if (props.mode !== "cashdiscount") {
            setTimeout(securePay.initSecurePay, 300);
          }
          window.addEventListener("message", securePay.receiveMessage);
        };
      }
    });

    onUnmounted(async () => {
      store.dispatch("payment/setPaymentUrl", "");
    });

    return {
      paymentUrl,
      t
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss"></style>
